import Contact from "../components/business/Contact";
import FAQ from "../components/business/FAQ";
import Features from "../components/business/Features";
import GetYourSpot from "../components/business/GetYourSpot";
import HowItWorks from "../components/business/HowItWorks";

const BusinessHome = () => {
    return (
        <>
            <Features />
            <HowItWorks />
            <GetYourSpot />
            <FAQ />
            <Contact />
        </>
    )
};

export default BusinessHome;