
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import bg from './locales/bg.json';
import en from './locales/en.json';

export const defaultNS = 'bg'
export const resources = {
    bg: {
        translation: bg
    },
    en: {
        translation: en
    },
    us: {
        translation: en
    }
};

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'bg',
        interpolation: {
            escapeValue: false,
        },
    });

export const changeLanguage = (l) => i18n.changeLanguage(l, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
})