import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { breakpoints } from '../theme';
import TranslatedText from '../components/TranslatedText';

const Wrapper = styled.div`
    background: #fff;
`;

const Title = styled.h1`
    text-align: center;
`;

const LastUpdated = styled.span`
    text-align: center;
`;

const List = styled.ol`
    counter-reset: item;
    ${breakpoints.mobileOnly} {
        padding: 0;
    }
`;

const Item = styled.li`
    display: block;
    padding: 8px 0;
    :before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight: bold;
    }
`;

const CookiePolicy = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    [breakpoints.tablet]: {
                        paddingTop: '96px',
                        paddingBottom: '337px',
                        position: 'relative'
                    }
                }}
            >
                <Title>
                    <TranslatedText string='cookie-policy.title' />
                </Title>
                <LastUpdated><TranslatedText string='cookie-policy.updated' /> 24.04.2023</LastUpdated>
                <List>
                    <Item><TranslatedText string='cookie-policy.introduction.title' />
                        <List>
                            {t('cookie-policy.introduction.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.what-are-cookies.title' />
                        <List>
                            {t('cookie-policy.what-are-cookies.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                            <ul>
                                {t('cookie-policy.what-are-cookies.inner-points', { returnObjects: true }).map((point, i) => (
                                    <li dangerouslySetInnerHTML={{ __html: point }} key={i} />
                                ))}
                            </ul>
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.types-of-cookies.title' />
                        <List>
                            {t('cookie-policy.types-of-cookies.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                            <table border='1px solid #000'>
                                <thead>
                                    <tr>
                                        {t('cookie-policy.types-of-cookies.table.header', { returnObjects: true }).map((point, i) => (
                                            <>
                                                <th>{point}</th>
                                            </>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {t('cookie-policy.types-of-cookies.table.rows', { returnObjects: true }).map((point, i) => (
                                        <tr>
                                            <td>{point.cookie}</td>
                                            <td>{point.purpose}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.information-kept.title' />
                        <List>
                            {t('cookie-policy.information-kept.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.used-cookies.title' />
                        <List>
                            {t('cookie-policy.used-cookies.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.control-cookies.title' />
                        <List>
                            {t('cookie-policy.control-cookies.points', { returnObjects: true }).map((point, i) =>
                                <Item key={i}>
                                    {typeof point === 'string' && <span dangerouslySetInnerHTML={{ __html: point }} />}
                                    {typeof point === 'object' && <>{point.title}<ul>{point.points.map((p, j) => <li dangerouslySetInnerHTML={{ __html: p }} key={j} />)}</ul></>}
                                </Item>
                            )
                            }
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.buttons-tools.title' />
                        <List>
                            {t('cookie-policy.buttons-tools.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i} dangerouslySetInnerHTML={{ __html: point }} />
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.changes-to-the-cookie-policy.title' />
                        <List>
                            {t('cookie-policy.changes-to-the-cookie-policy.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='cookie-policy.contacts.title' />
                        <List>
                            {t('cookie-policy.contacts.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i} dangerouslySetInnerHTML={{ __html: point }} />
                            ))}
                        </List>
                    </Item>
                </List >
            </Container >
        </Wrapper>
    );
}

export default CookiePolicy;