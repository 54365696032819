import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { breakpoints } from '../theme';
import TranslatedText from '../components/TranslatedText';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
const Wrapper = styled.div`
    background: #fff;
`;

const Title = styled.h1`
    text-align: center;
`;

const LastUpdated = styled.span`
    text-align: center;
`;

const List = styled.ol`
    counter-reset: item;
    ${breakpoints.mobileOnly} {
        padding: 0;
    }
`;

const Item = styled.li`
    display: block;
    padding: 8px 0;
    :before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight: bold;
    }
`;

const DeleteAccount = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    [breakpoints.tablet]: {
                        paddingTop: '96px',
                        paddingBottom: '337px',
                        position: 'relative'
                    }
                }}
            >
                <Title>
                    <TranslatedText string='delete-account.title' />
                </Title>
                {t('delete-account.steps', { returnObjects: true }).map((point, i) => (
                    <Box key={i} sx={{
                        mt: 3
                    }}>
                        <Typography sx={{ fontWeight: 'bold' }}>{t('delete-account.step')} {i + 1}: {point.title}</Typography>
                        {point.description}
                    </Box>
                ))}
                <Typography sx={{ fontWeight: 'bold', textAlign: 'left', mt: 3, width: '100%' }}>
                    {/* <TranslatedText string='delete-account.personal-information.title' /> */}
                    {t('delete-account.personal-information.title')}
                </Typography>
                <TranslatedText string='delete-account.personal-information.description' />
            </Container >
        </Wrapper>
    );
}

export default DeleteAccount;