import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Navigation from "./Navigation";
import TranslatedText from "../TranslatedText";
import GooglePlay from '../../images/google-play.svg';
import AppStore from '../../images/app-store.svg';
import HeaderBackground from '../../images/user/header-background.svg';
import HeaderPhonesWebp from '../../images/user/header-phones.webp';
import { breakpoints } from '../../theme';

const HeaderContainer = styled.div`
    background-image: ${(props) => props.isSpecialOffer ? 'none' : `url(${HeaderBackground})`};
    background-size: cover;
    background-repeat: no-repeat;
    position: ${(props) => props.isHome ? 'relative' : 'sticky'};
    top: 0;
    z-index: 9999;
    ${[breakpoints.tablet]} {
        background-position: center center;
    }
`;

const Title = styled.h1`
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    margin: 0;
    color: #fff;
    font-family: dela;
    ${[breakpoints.tablet]} {
        font-size: 56px;
    }
`;

const Subtitle = styled.h3`
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.15px;
    margin: 16px 0 0 0;
    color: #fff;
`;

const Buttons = styled.div`
    margin-top: 64px;
    display: flex;
    align-items: center;
    gap: 32px;
`;

const Button = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: props.target,
    disabled: props.disabled
}))`
    position: relative;
    &[disabled] {
        pointer-events: none;
        cursor: default;
    }
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    display: block;
`;

const PhonesImage = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;
    max-width: 880px;
`;

const PATH_HOME = '/';
const PATH_SPECIAL_OFFER = '/special-offer';

const Header = () => {
    const location = useLocation();
    const [pathUrl, setPathUrl] = useState('');

    useEffect(() => {
        setPathUrl(location.pathname);
    }, [location]);

    return (
        <HeaderContainer isHome={pathUrl === PATH_HOME} isSpecialOffer={pathUrl === PATH_SPECIAL_OFFER}>
            <Navigation />
            {pathUrl === PATH_HOME &&
                <Container maxWidth="lg" sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    [breakpoints.tablet]: {
                        flexDirection: 'row'
                    }
                }}>
                    <Box sx={{
                        order: 1,
                        [breakpoints.mobileOnly]: {
                            padding: '36px 20px 64px 20px'
                        },
                        [breakpoints.tablet]: {
                            order: 0,
                            flex: '0 0 40%',
                            padding: '77px 0 150px 0'
                        }
                    }}>
                        <Title><TranslatedText string='header.title' /></Title>
                        <Subtitle><TranslatedText string='header.subtitle' /></Subtitle>
                        <Buttons>
                            <Button href="https://play.google.com/store/apps/details?id=bg.myspot" target="_blank">
                                <Image src={GooglePlay} alt="google play" />
                            </Button>
                            <Button href="https://apps.apple.com/bg/app/myspot-book-your-dream-spot/id6453559634" target="_blank">
                                <Image src={AppStore} alt="app store" />
                            </Button>
                        </Buttons>
                    </Box>
                    <Box sx={{
                        order: 0,
                        [breakpoints.mobileOnly]: {
                            display: 'none'
                        },
                        [breakpoints.tablet]: {
                            order: 1,
                            position: 'absolute',
                            right: '-135px',
                            bottom: '-190px',
                        }
                    }}>
                        <PhonesImage src={HeaderPhonesWebp} />
                        {/* <ImgWithFallback
                        src={HeaderPhonesWebp}
                        fallback={HeaderPhonesPNG}
                        alt='header phones'
                    /> */}
                    </Box>
                </Container>
            }
        </HeaderContainer>
    )
}

export default Header;