import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TranslatedText from './TranslatedText';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#fff',
    border: 'none',
    flexDirection: 'row-reverse',
    padding: 0,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export default function CustomizedAccordions({ data, sx }) {
    const [expanded, setExpanded] = React.useState('panel0');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    if (!data) return null;
    return (
        <Box sx={sx}>
            {data.map((d, i) => {
                return (
                    <Accordion
                        key={i}
                        expanded={expanded === `panel${i}`}
                        onChange={handleChange(`panel${i}`)}
                        sx={{
                            border: 'none',
                            outline: 'none',
                        }}
                    >
                        <AccordionSummary aria-controls={`panel${i}d-content`} id={`panel${i}d-header`} sx={{
                            borderBottom: expanded === `panel${i}` ? 'none' : '1px dashed #9496A5',
                            minHeight: 'auto',
                            padding: '24px 0',
                            '.MuiAccordionSummary-content': {
                                margin: 0
                            }
                        }}>
                            <Typography sx={{
                                fontWeight: '700',
                                fontSize: '22px',
                                lineHeight: '28px',
                                color: '#044D5E',
                            }}>
                                <TranslatedText string={d.title} />
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                borderBottom: '1px dashed #9496A5',
                                padding: '0 0 24px 0'
                            }}
                        >
                            <Typography>
                                <TranslatedText string={d.description} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Box>
    );
}