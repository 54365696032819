import { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { breakpoints } from '../theme';
import logoWhite from '../images/logo-white.svg';
import iconFree from '../images/icon-free.svg';
import TitleLine from '../images/title-line.svg';
import GooglePlayColored from '../images/google-play-colored.svg';
import AppStoreColored from '../images/app-store-colored.svg';
import backgroundLines from '../images/background-lines.webp';
import backgroundPhone from '../images/background-phone.webp';
import TranslatedText from "../components/TranslatedText";

const Wrapper = styled.div`
    background-image: url(${backgroundPhone});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    ${[breakpoints.tablet]} {
        background-image: url(${backgroundLines});
    }
`;

const Header = styled.div`
    background: #06191E;
    border-radius: 0px 0px 64px 64px;
    height: 254px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    ${[breakpoints.tablet]} {
        height: 251px;
    }
`;

const Logo = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    display: block;
    ${[breakpoints.tablet]} {
        display: none;
    }
`;

const IconFree = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    position: absolute;
    bottom: -89px;
    left: 50%;
    transform: translate(-50%, 0);
    ${[breakpoints.tablet]} {
        width: 221px;
        bottom: -110.5px;
    }
`;

const Powered = styled.span`
    display: inline-flex;
    justify-content: center;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #fff;
    opacity: 0.6;
    margin-top: 16px;
    ${[breakpoints.tablet]} {
        display: none;
    }
`;

const Title = styled.h1`
    font-family: dela;
    color: #C5FFFF;
    width: 100%;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    &::first-line {
        font-weight: 400;
        font-size: 24px;
        
    }
`;

const Content = styled.div`
    margin-top: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    ${[breakpoints.tablet]} {
        margin-top: 145px;
        padding: 0;
    }
`;

const HowItWorks = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const Steps = styled.ul`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #06191E;
    list-style-type: decimal;
    margin-top: 8px;
    margin-bottom: 0;
    padding-left: 15px;
`;

const Step = styled.li`
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    &:first-child {
        margin-top: 32px;
    }
`;

const Buttons = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 32px;
    ${[breakpoints.mobileOnly]} {
        justify-content: center;
    }
`;

const Button = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: props.target,
    disabled: props.disabled
}))`
    position: relative;
    &[disabled] {
        pointer-events: none;
        cursor: default;
    }
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;
`;

const ScannerResult = () => {
    return (
        <Wrapper>
            <Container maxWidth="lg" sx={{
                [breakpoints.mobileOnly]: {
                    padding: 0
                }
            }}>
                <Box>
                    <Header>
                        <Powered>Powered by</Powered>
                        <Logo src={logoWhite} />
                        <Title><TranslatedText string='scanner.header.free' html={true} /></Title>
                        <IconFree src={iconFree} />
                    </Header>
                    <Content>
                        <HowItWorks>
                            <TranslatedText string='scanner.how-it-works' />
                        </HowItWorks>
                        <Line src={TitleLine} />
                        <Steps>
                            <TranslatedText string='scanner.steps' />
                            {[1, 2, 3, 4].map((step, i) => {
                                return (
                                    <Step key={i}>
                                        <TranslatedText string={`scanner.step-${step}`} />
                                    </Step>
                                )
                            })}
                        </Steps>
                        <Buttons>
                            <Button href="https://play.google.com/store/apps/details?id=bg.myspot" target="_blank">
                                <Image src={GooglePlayColored} alt="google play" />
                            </Button>
                            <Button href="https://apps.apple.com/bg/app/myspot-book-your-dream-spot/id6453559634" target="_blank">
                                <Image src={AppStoreColored} alt="app store" />
                            </Button>
                        </Buttons>
                    </Content>
                </Box>
            </Container>
        </Wrapper>
    )
}

export default ScannerResult;