import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Navigation from "./Navigation";
import TranslatedText from "../TranslatedText";
import HeaderBackground from '../../images/business/header-background.svg';
import HeaderImageWebp from '../../images/business/header-image.webp';
import { breakpoints } from '../../theme';

const HeaderContainer = styled.div`
    // background: #044D5E;
    background-image: url(${HeaderBackground});
    background-size: cover;
    background-repeat: no-repeat;
    ${[breakpoints.tablet]} {
        background-position: center center;
    }
`;

const Title = styled.h1`
    font-weight: 400;
    font-size: 56px;
    line-height: 72px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
    ${[breakpoints.mobileOnly]} {
        font-size: 36px;
        line-height: 42px;
    }
`;

const Buttons = styled.div`
    margin-top: 64px;
    display: flex;
    align-items: center;
    gap: 16px;
    ${[breakpoints.mobileOnly]} {
        margin-top: 32px;
    }
`;

const ButtonSignUp = styled.a.attrs((props) => ({
    href: props.href || '#'
}))`
    background: #06191E;
    border-radius: 30px;
    padding: 16px 24px;
    text-decoration: none;
    color: #ABFFC1;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
`;

const ButtonContactSale = styled.a.attrs((props) => ({
    href: props.href || '#'
}))`
    background: #ABFFC1;
    border: 2px solid #06191E;
    border-radius: 30px;
    padding: 16px 24px;
    text-decoration: none;
    color: #06191E;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`;

const PhonesImage = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;
    max-width: 840px;
`;

const PATH_HOME = '/business';
const BusinessHeader = () => {
    const location = useLocation();
    const [pathUrl, setPathUrl] = useState('');

    useEffect(() => {
        setPathUrl(location.pathname);
    }, [location]);
    return (
        <HeaderContainer>
            <Navigation />
            {pathUrl === PATH_HOME &&
                <Container maxWidth="xl" sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    maxWidth: '1440px',
                    [breakpoints.tablet]: {
                        flexDirection: 'row'
                    }
                }}>
                    <Box sx={{
                        order: 1,
                        [breakpoints.mobileOnly]: {
                            padding: '36px 20px 64px 20px'
                        },
                        [breakpoints.tablet]: {
                            order: 0,
                            flex: '0 0 50%',
                            padding: '77px 0 150px 0'
                        }
                    }}>
                        <Title>
                            <TranslatedText string='header-business.title' />
                        </Title>
                        <Buttons>
                            <ButtonSignUp href="#contact-section">
                                <TranslatedText string='signup' />
                            </ButtonSignUp>
                            <ButtonContactSale href="#contact-section">
                                <TranslatedText string='contact-sales' />
                            </ButtonContactSale>
                        </Buttons>
                    </Box>
                    <Box sx={{
                        order: 0,
                        display: 'flex',
                        [breakpoints.mobileOnly]: {
                            display: 'none'
                        },
                        [breakpoints.tablet]: {
                            order: 1,
                            position: 'absolute',
                            right: '0',
                            bottom: '0',
                        }
                    }}>
                        <PhonesImage src={HeaderImageWebp} />
                        {/* <ImgWithFallback
                        src={HeaderPhonesWebp}
                        fallback={HeaderPhonesPNG}
                        alt='header phones'
                    /> */}
                    </Box>
                </Container>
            }
        </HeaderContainer>
    )
}

export default BusinessHeader;