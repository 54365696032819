import {
    Outlet,
} from "react-router-dom";
import Header from "../business/Header";
import Footer from "../business/Footer";
import { Helmet } from "react-helmet";

const BusinessLayout = () => {
    return (
        <>
            <Helmet>
                <title>Boost Your Beach Business with Myspot Reservation Management System | Sign Up Now</title>
                <meta name="description" content="Simplify your operations with our powerful beach reservation system. Manage bookings, track occupancy, and increase revenue with ease. Submit your beach location today." />
            </Helmet>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default BusinessLayout;