import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { breakpoints } from '../theme';
import TranslatedText from '../components/TranslatedText';

const Wrapper = styled.div`
    background: #fff;
`;

const Title = styled.h1`
    text-align: center;
`;

const LastUpdated = styled.span`
    text-align: center;
`;

const List = styled.ol`
    counter-reset: item;
    ${breakpoints.mobileOnly} {
        padding: 0;
    }
`;

const Item = styled.li`
    display: block;
    padding: 8px 0;
    :before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight: bold;
    }
`;

const Terms = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    [breakpoints.tablet]: {
                        paddingTop: '96px',
                        paddingBottom: '337px',
                        position: 'relative'
                    }
                }}
            >
                <Title>
                    <TranslatedText string='terms-and-conditions.title' />
                </Title>
                <LastUpdated><TranslatedText string='terms-and-conditions.updated' /> 27.04.2023</LastUpdated>
                <List>
                    <Item><TranslatedText string='terms-and-conditions.introduction.title' />
                        <List>
                            {t('terms-and-conditions.introduction.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i} dangerouslySetInnerHTML={{ __html: point }} />
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.purpose-and-scope.title' />
                        <List>
                            {t('terms-and-conditions.purpose-and-scope.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i} dangerouslySetInnerHTML={{ __html: point }} />
                            ))}
                        </List>
                    </Item>
                    <Item>
                        <TranslatedText string='terms-and-conditions.definitions.title' />
                        <List>
                            {t('terms-and-conditions.definitions.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point.title}
                                    {point.points && <List>{
                                        point.points.map((p, j) => <Item key={j}>
                                            {typeof p === 'string' && <>{p}</>}
                                            {typeof p === 'object' && <>
                                                {p.title}
                                                <ul>
                                                    {p.inner.map((po, j) => <li key={j} >{po}</li>)}
                                                </ul>
                                            </>}
                                        </Item>)
                                    }</List>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.identification.title' />
                        <List>
                            {t('terms-and-conditions.identification.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i} >
                                    {typeof point === 'string' && <span dangerouslySetInnerHTML={{ __html: point }} />}
                                    {typeof point === 'object' && <>{point.title}<ul>{point.points.map((p, j) => <li key={j} >{p}</li>)}</ul></>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.information-supervisory-authorities.title' />
                        <List>
                            {t('terms-and-conditions.information-supervisory-authorities.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point.title}
                                    <ul>
                                        {point.inner.map((po, j) => <li dangerouslySetInnerHTML={{ __html: po }} />)}
                                    </ul>
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.subject-matter.title' />
                        <List>
                            {t('terms-and-conditions.subject-matter.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.characteristics-of-the-application.title' />
                        <List>
                            {t('terms-and-conditions.characteristics-of-the-application.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {typeof point === 'string' && <>{point}</>}
                                    {typeof point === 'object' && <>{point.title}<ul>{point.inner.map((p, j) => <li key={j} >{p}</li>)}</ul></>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.installation.title' />
                        <List>
                            {t('terms-and-conditions.installation.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.registrаtion.title' />
                        <List>
                            {t('terms-and-conditions.registrаtion.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.spot-and-service-booking.title' />
                        <List>
                            {t('terms-and-conditions.spot-and-service-booking.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.scanner.title' />
                        <List>
                            {t('terms-and-conditions.scanner.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.pricing-and-payment.title' />
                        <List>
                            {t('terms-and-conditions.pricing-and-payment.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {typeof point === 'string' && <>{point}</>}
                                    {typeof point === 'object' && <>{point.title}<ul>{point.inner.map((p, j) => <li key={j} >{p}</li>)}</ul></>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.commission.title' />
                        <List>
                            {t('terms-and-conditions.commission.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.cancellation.title' />
                        <List>
                            {t('terms-and-conditions.cancellation.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {typeof point === 'string' && <>{point}</>}
                                    {typeof point === 'object' && <>{point.title}<ul>{point.inner.map((p, j) => <li key={j} >{p}</li>)}</ul></>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.reimbursement-credits-refunds.title' />
                        <List>
                            {t('terms-and-conditions.reimbursement-credits-refunds.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.force-majeure.title' />
                        <List>
                            {t('terms-and-conditions.force-majeure.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.rights-obligations-liability.title' />
                        <List>
                            {t('terms-and-conditions.rights-obligations-liability.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <>{point.title}
                                        <ul>
                                            {point.inner.map((p, j) => <Item key={j}>{p}</Item>)}
                                        </ul>
                                    </>
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.rights-and-obligations-of-the-provider.title' />
                        <List>
                            {t('terms-and-conditions.rights-and-obligations-of-the-provider.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.personal-data-protection.title' />
                        <List>
                            {t('terms-and-conditions.personal-data-protection.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.exemption-from-liability.title' />
                        <List>
                            {t('terms-and-conditions.exemption-from-liability.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.dispute-resolution.title' />
                        <List>
                            {t('terms-and-conditions.dispute-resolution.points', { returnObjects: true }).map((point, i) => (
                                <Item dangerouslySetInnerHTML={{ __html: point }} key={i} />
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.links-to-third-party.title' />
                        <List>
                            {t('terms-and-conditions.links-to-third-party.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.newsletter-subscription.title' />
                        <List>
                            {t('terms-and-conditions.newsletter-subscription.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.push-notifications.title' />
                        <List>
                            {t('terms-and-conditions.push-notifications.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.intellectual-property.title' />
                        <List>
                            {t('terms-and-conditions.intellectual-property.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.transfer-of-rights.title' />
                        <List>
                            {t('terms-and-conditions.transfer-of-rights.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>{point}</Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.breach-and-termination.title' />
                        <List>
                            {t('terms-and-conditions.breach-and-termination.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {typeof point === 'string' && <>{point}</>}
                                    {typeof point === 'object' && <>{point.title}<ul>{point.inner.map((p, j) => <li key={j} >{p}</li>)}</ul></>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='terms-and-conditions.final-provisions.title' />
                        <List>
                            {t('terms-and-conditions.final-provisions.points', { returnObjects: true }).map((point, i) => (
                                <Item dangerouslySetInnerHTML={{ __html: point }} key={i} />
                            ))}
                        </List>
                    </Item>
                </List>
            </Container>
        </Wrapper>
    );
}

export default Terms;