import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import TranslatedText from '../TranslatedText';
import LogoImage from '../../images/logo-white.svg';
import { breakpoints } from '../../theme';
import { changeLanguage } from '../../localization/i18n';

const pages = [
    {
        id: "features",
        text: "navigation.features",
        isSPA: false
    },
    {
        id: "how-it-works",
        text: "navigation.how-it-works",
        isSPA: false
    },
    {
        id: "download",
        text: "navigation.download-app",
        isSPA: false
    },
    {
        id: "special-offers",
        text: "navigation.special-offers",
        isSPA: true,
        link: '/special-offer'
    },
    {
        id: "faq",
        text: "navigation.faq",
        isSPA: false
    }
];

const GoToBusiness = styled.a.attrs((props) => ({
    href: props.to || '#',
    target: '_blank'
}))`
    display: inline-flex;
    background: #C5FFFF;
    color: #044D5E;
    padding: 16px 24px;
    border-radius: 30px;
    font-weight: 700;
    text-decoration: none;
    ${[breakpoints.mobileOnly]} {
        margin-top: 16px;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
    }
`;

const Logo = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`

`;

const CloseBtn = styled.div`
    display: flex;
    justify-content: end;
`;

const LogoLink = styled(Link).attrs((props) => ({
    to: props.to || '#'
}))`
    display: block;
`;

const SPALink = styled(Link).attrs((props) => ({
    to: props.to || ''
}))`
    ${[breakpoints.mobileOnly]} {
        text-transform: initial;
        margin: 0;
    }
    color: #ABFFC1;
    display: block;
    fontWeight: 700;
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    text-decoration: none;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0;
`;

const languages = ['bg', 'en'];
const PATH_HOME = '/';

const Navigation = () => {
    const location = useLocation();
    const [pathUrl, setPathUrl] = useState('');

    useEffect(() => {
        setPathUrl(location.pathname);
    }, [location]);

    const [selectedLanguage, setSelectedLanguage] = useState(languages.filter(l => {
        return l !== i18n.language;
    }));
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleNavigate = (page) => {
        const id = `${page}-section`;
        const el = document.getElementById(id);
        if (el) {
            handleCloseNavMenu();
            const boundingTop = el.getBoundingClientRect().top;
            window.scrollTo({top: boundingTop, behavior: 'smooth'});
        }
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleChangeLanguage = (lng) => {
        setSelectedLanguage(languages.filter(l => l !== lng));
        changeLanguage(lng);
    }
    return (
        <AppBar position="static" sx={{
            background: 'transparent',
            boxShadow: 'none'
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{
                    height: pathUrl === PATH_HOME ? '116px' : '68px'
                }}>
                    <LogoLink to='/'>
                        <Logo src={LogoImage} />
                    </LogoLink>
                    <Box sx={{
                        flexGrow: 1,
                        display: { xs: 'flex', md: 'none' },
                        [breakpoints.mobileOnly]: {
                            justifyContent: 'end'
                        }
                    }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon fontSize='large' />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            marginThreshold={0}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                zIndex: '9999',

                                '& .MuiPaper-elevation': {
                                    position: 'relative',
                                    top: 'unset !important',
                                    left: 'unset !important',
                                    boxShadow: 'none',
                                    width: '100%',
                                    maxWidth: '100%',
                                    maxHeight: '100%'
                                }
                            }}
                        >
                            <CloseBtn onClick={handleCloseNavMenu}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <CloseIcon fontSize='large' />
                                </IconButton>
                            </CloseBtn>
                            {pages.map((page) => (
                                page.isSPA ?
                                    <SPALink to={page.link} key={page.id} onClick={handleCloseNavMenu}>
                                        <MenuItem>
                                            <Typography textAlign="center" sx={{
                                                color: '#000',
                                                fontWeight: 700
                                            }}>
                                                <TranslatedText string={page.text} />
                                            </Typography>
                                        </MenuItem>
                                    </SPALink>
                                    :
                                    <MenuItem
                                        key={page.id}
                                        sx={{
                                            fontWeight: 700
                                        }}
                                        onClick={() => handleNavigate(page.id)}>
                                        <Typography textAlign="center" sx={{
                                            color: '#000',
                                            fontWeight: 700
                                        }}>
                                            <TranslatedText string={page.text} />
                                        </Typography>
                                    </MenuItem>
                            ))}
                            <GoToBusiness to="https://app.myspot.bg">
                                <TranslatedText string='navigation.app-login' />
                            </GoToBusiness>
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, gap: '64px', justifyContent: 'center' }}>
                        {pages.map((page) => (
                            page.isSPA ?
                                <SPALink to={page.link} key={page.id} onClick={handleCloseNavMenu}>
                                    <TranslatedText string={page.text} />
                                </SPALink>
                                :
                                <Button
                                    key={page.id}
                                    onClick={() => handleNavigate(page.id)}
                                    sx={{ my: 2, color: '#ABFFC1', display: 'block', fontWeight: 700, padding: 0 }}
                                >
                                    <TranslatedText string={page.text} />
                                </Button>
                        ))}
                    </Box>
                    <Box sx={{
                        flexGrow: 0,
                        [breakpoints.mobileOnly]: {
                            display: 'none'
                        }
                    }}>
                        <GoToBusiness to="https://app.myspot.bg">
                            <TranslatedText string='navigation.app-login' />
                        </GoToBusiness>
                    </Box>
                    <Box sx={{
                        marginLeft: '16px'
                    }}>
                        <Button sx={{
                            display: 'flex',
                            background: '#fffe68',
                            color: '#044d5e',
                            borderRadius: '30px',
                            fontWeight: '700',
                            fontSize: '14px',
                            lineHeight: '20px',
                            letterSpacing: '0.1px',
                            textDecoration: 'none',
                            outline: 'none',
                            width: '52px',
                            height: '52px',
                            padding: 0,
                            border: 'none',
                            '&:hover': {
                                background: '#044D5E',
                                color: '#C5FFFF',
                            }
                        }} onClick={() => handleChangeLanguage(selectedLanguage[0])}>{selectedLanguage[0]}</Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar >
    );
}
export default Navigation;