import { useRef } from 'react';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TranslatedText from '../TranslatedText';
import TitleLine from '../../images/title-line.svg';
import FAQIcon from '../../images/faq.svg';
import Accordion from '../Accordion';
import { breakpoints } from '../../theme';

const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const ImageContainer = styled.div`
    position: relative;
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;
`;

const Wrapper = styled.div`
    background: #fff;
`;

const data = [
    {
        title: 'faq.payment-methods.title',
        description: 'faq.payment-methods.description'
    },
    {
        title: 'faq.myspot-credits.title',
        description: 'faq.myspot-credits.description'
    },
    {
        title: 'faq.reserve.title',
        description: 'faq.reserve.description'
    },
    {
        title: 'faq.contact-team.title',
        description: 'faq.contact-team.description'
    },
];


const FAQ = () => {
    const ref = useRef('faq');
    return (
        <Wrapper>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    background: '#fff',
                    [breakpoints.tablet]: {
                        flexDirection: 'row',
                        paddingTop: '180px',
                        paddingBottom: '337px',
                        position: 'relative'
                    }
                }}
            >
                <Box sx={{
                    flex: 1
                }}>
                    <ImageContainer>
                        <Image src={FAQIcon} />
                    </ImageContainer>
                </Box>
                <Box sx={{
                    flex: 1,
                    [breakpoints.tablet]: {
                        paddingLeft: '60px'
                    }
                }}>
                    <Title id='faq-section'>
                        <TranslatedText string='faq.title' />
                    </Title>
                    <Line src={TitleLine} />
                    <Accordion data={data} sx={{
                        marginTop: '16px',
                        [breakpoints.tablet]: {
                            marginTop: '76px'
                        }
                    }} />
                </Box>
            </Container>
        </Wrapper>
    )
}

export default FAQ;