import Features from '../components/user/Features';
import HowItWorks from '../components/user/HowItWorks';
import GetYourSpot from '../components/user/GetYourSpot';
import YouNeedASpot from '../components/user/YouNeedASpot';
import FAQ from '../components/user/FAQ';

const Home = () => {
    return (
        <div>
            <Features />
            <HowItWorks />
            <GetYourSpot />
            <YouNeedASpot />
            <FAQ />
        </div>
    )
}

export default Home;