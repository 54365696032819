const mobileOnly = '@media screen and (max-width: 600px)';
const tablet = '@media screen and (min-width: 601px)';
const desktop = '@media screen and (min-width: 960px)';
const larger = '@media screen and (min-width: 1200px)';

export const breakpoints = {
    mobileOnly,
    tablet,
    desktop,
    larger
};