import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { breakpoints } from '../theme';
import TranslatedText from '../components/TranslatedText';
import { Box } from '@mui/material';
import PaymentFailureIcon from '../images/user/payment-failure.png';

const Wrapper = styled.div`
    background: #fff;
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    ${(props) => props.sx}
`;

const Title = styled.h1`
    text-align: center;
`;

const PaymentFailure = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    [breakpoints.tablet]: {
                        paddingTop: '96px',
                        paddingBottom: '337px',
                        position: 'relative'
                    }
                }}
            >
                <Box textAlign="center">
                    <Image src={PaymentFailureIcon} alt="Unsuccessful payment icon" boxSize="300px" objectFit="cover" m={5} />
                    <Title>
                        <TranslatedText string='payment.failure-message' />
                    </Title>
                </Box>
            </Container >
        </Wrapper>
    );
}

export default PaymentFailure;