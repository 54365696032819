import * as React from 'react';
import { useTranslation, Trans } from "react-i18next";

const TranslatedText = ({ string, html }) => {
    const { t } = useTranslation();

    return (
        html ? <Trans i18nKey={`${string}`}>{t(string)}</Trans> : <>{t(string)}</>
    )
}

export default TranslatedText;