import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TranslatedText from '../TranslatedText';
import LogoImage from '../../images/logo-white.svg';
import GooglePlayColored from '../../images/google-play-colored.svg';
import AppStoreColored from '../../images/app-store-colored.svg';
import { breakpoints } from '../../theme';

const Wrapper = styled.div`
    background: ${(props) => props.isSpecialOffer ? 'transparent' : '#fff'};
`;

const FooterContainer = styled.div`
    background: #06191E;
    border-radius: 80px 80px 0 0;
    position: relative; 
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;
`;

const Logo = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`

`;

const FooterHeadLink = styled.a.attrs((props) => ({
    href: props.src || ''
}))`
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    text-decoration: none;
`;

const FooterGoToBusiness = styled(Link).attrs((props) => ({
    to: props.to || '#'
}))`
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    text-decoration: none;
`;

const FooterLegalLink = styled(Link).attrs((props) => ({
    to: props.to || ''
}))`
    color: #C5FFFF;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    text-decoration: none;
`;

const Buttons = styled.div`
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
`;

const Button = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: props.target,
    disabled: props.disabled
}))`
    position: relative;
    &[disabled] {
        pointer-events: none;
        cursor: default;
    }
`;

const Copyrights = styled.p`
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    opacity: 0.5;
    text-align: center;
    margin-top: 56px;
`;

const LogoLink = styled(Link).attrs((props) => ({
    to: props.to || '#'
}))`
    display: block;
`;

const PATH_SPECIAL_OFFER = '/special-offer';

const Footer = () => {
    const location = useLocation();
    const [pathUrl, setPathUrl] = useState('');

    useEffect(() => {
        setPathUrl(location.pathname);
    }, [location]);
    return (
        <Wrapper isSpecialOffer={pathUrl === PATH_SPECIAL_OFFER}>
            <FooterContainer>
                <Container maxWidth="lg">
                    <Box sx={{
                        padding: '64px 0 22px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        [breakpoints.tablet]: {
                            padding: '24px 0 23px 0',
                        }
                    }}>
                        <LogoLink to='/'>
                            <Logo src={LogoImage} />
                        </LogoLink>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        padding: '32px 0',
                        [breakpoints.tablet]: {
                            padding: '0 0 32px 0',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '64px',
                        }
                    }}>
                        <FooterHeadLink src=''><TranslatedText string='footer.features' /></FooterHeadLink>
                        <FooterHeadLink src=''><TranslatedText string='footer.how-it-works' /></FooterHeadLink>
                        <FooterHeadLink src=''><TranslatedText string='footer.download-app' /></FooterHeadLink>
                        <FooterHeadLink src=''><TranslatedText string='footer.faq' /></FooterHeadLink>
                        <FooterGoToBusiness to='/business'><TranslatedText string='footer.go-to-business' /></FooterGoToBusiness>
                    </Box>
                </Container>
                <Box sx={{
                    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    padding: '32px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    [breakpoints.tablet]: {
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '64px',
                    }
                }}>
                    <FooterLegalLink to='terms'><TranslatedText string='footer.terms' /></FooterLegalLink>
                    <FooterLegalLink to='privacy-policy'><TranslatedText string='footer.privacy-policy' /></FooterLegalLink>
                    <FooterLegalLink to='cookie-policy'><TranslatedText string='footer.cookie-policy' /></FooterLegalLink>
                </Box>
                <Container maxWidth="lg" sx={{
                    paddingTop: '32px',
                    paddingBottom: '24px'
                }}>
                    <Box>
                        <Buttons>
                            <Button href="https://play.google.com/store/apps/details?id=bg.myspot" target="_blank">
                                <Image src={GooglePlayColored} alt="google play" />
                            </Button>
                            <Button href="https://apps.apple.com/bg/app/myspot-book-your-dream-spot/id6453559634" target="_blank">
                                <Image src={AppStoreColored} alt="app store" />
                            </Button>
                        </Buttons>
                        <Copyrights>© <TranslatedText string='footer.copyrights' /></Copyrights>
                    </Box>
                </Container>
            </FooterContainer>
        </Wrapper>
    )
}

export default Footer;