import styled from 'styled-components';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TranslatedText from '../TranslatedText';
import TitleLine from '../../images/title-line.svg';
import HowItWorksIcon from '../../images/business/how-it-works.svg';
import { breakpoints } from '../../theme';

const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const ImageContainer = styled.div`
    position: relative;
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy',
}))`
    width: 100%;
`;

const Steps = styled.ul`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #06191E;
    list-style-type: decimal;
    margin-top: 24px;
    margin-bottom: 0;
    padding-left: 15px;
    max-width: 420px;
`;

const Step = styled.li`
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    &:first-child {
        margin-top: 24px;
    }
`;

const Buttons = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 32px;
    ${[breakpoints.mobileOnly]} {
        justify-content: center;
    }
`;

const Wrapper = styled.div`
    position: relative;
    background: #fff;
`;

const ButtonSignUp = styled.a.attrs((props) => ({
    href: props.href || '#'
}))`
    background: #06191E;
    border-radius: 30px;
    padding: 16px 24px;
    text-decoration: none;
    color: #ABFFC1;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
`;

const ButtonContactSale = styled.a.attrs((props) => ({
    href: props.href || '#'
}))`
    background: #ABFFC1;
    border: 2px solid #06191E;
    border-radius: 30px;
    padding: 16px 24px;
    text-decoration: none;
    color: #06191E;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
`;

const HowItWorks = () => {
    return (
        <Wrapper id='how-it-works-section'>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    [breakpoints.tablet]: {
                        flexDirection: 'row',
                        paddingTop: '98px',
                        paddingBottom: '98px',
                        position: 'relative',
                    },
                }}
            >
                <Box sx={{
                    flex: 1,
                    [breakpoints.mobileOnly]: {
                        order: 1,
                        marginTop: '48px'
                    },
                }}>
                    <ImageContainer>
                        <Image src={HowItWorksIcon} />
                    </ImageContainer>
                </Box>
                <Box sx={{
                    flex: 1,
                    [breakpoints.mobileOnly]: {
                        order: 0
                    },
                    [breakpoints.tablet]: {
                        paddingLeft: '60px',
                    }
                }}>
                    <Title>
                        <TranslatedText string='how-it-works-business.title' />
                    </Title>
                    <Line src={TitleLine} />
                    <Steps>
                        {[1, 2, 3, 4, 5].map((step, i) => {
                            return (
                                <Step key={i}>
                                    <TranslatedText string={`how-it-works-business.step-${step}`} />
                                </Step>
                            )
                        })}
                    </Steps>
                    <Buttons>
                        <ButtonSignUp href="#contact-section">
                            <TranslatedText string='signup' />
                        </ButtonSignUp>
                        <ButtonContactSale href="#contact-section">
                            <TranslatedText string='contact-sales' />
                        </ButtonContactSale>
                    </Buttons>
                </Box>
            </Container>
        </Wrapper>
    )
}

export default HowItWorks;