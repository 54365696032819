import { useState } from 'react';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import TranslatedText from '../TranslatedText';
import TitleLine from '../../images/title-line.svg';
import HowItWorksIcon from '../../images/user/how-it-works.svg';
import PlayButton from '../../images/play-button.svg';
import GooglePlayColored from '../../images/google-play-colored.svg';
import AppStoreColored from '../../images/app-store-colored.svg';
import BrushLeft from '../../images/brush-left.svg';
import { breakpoints } from '../../theme';
import howItWorksVideo from '../../images/how_it_works.mp4';

const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    width: 'calc(100vw - 40%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    [breakpoints.mobileOnly]: {
        width: '90%'
    }
};
const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const ImageContainer = styled.div`
    position: relative;
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;
`;

const PlayButtonContainer = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 101;
`;

const Steps = styled.ul`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #06191E;
    list-style-type: decimal;
    margin-top: 24px;
    margin-bottom: 0;
    padding-left: 15px;
`;

const Step = styled.li`
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    &:first-child {
        margin-top: 24px;
    }
`;

const Buttons = styled.div`
    margin-top: 64px;
    display: flex;
    align-items: center;
    gap: 32px;
    ${[breakpoints.mobileOnly]} {
        justify-content: center;
    }
`;

const Button = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: props.target,
    disabled: props.disabled
}))`
    position: relative;
    &[disabled] {
        pointer-events: none;
        cursor: default;
    }
`;

const Wrapper = styled.div`
    position: relative;
    background: #fff;
`;

const Brush = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    position: absolute;
    bottom: -50px;
    left: 0;
`;

const CloseVideo = styled.div`
    display: inline-flex;
    justify-content: end;
    background: #044D5E;
    border-radius: 100%;
    padding: 8px;
    margin-bottom: 8px;
    color: #fff;
    cursor: pointer;
`;

const Video = styled.video.attrs((props) => ({
    controls: true,
    autoPlay: true,
    preload: 'none'
}))`
    width: 100%;
    border-radius: 12px;
    z-index: 102;
`;

const HowItWorks = () => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Wrapper id='how-it-works-section'>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    [breakpoints.tablet]: {
                        flexDirection: 'row',
                        paddingTop: '248px',
                        paddingBottom: '135px',
                        position: 'relative',
                    },
                }}
            >
                <Box sx={{
                    flex: 1,
                    [breakpoints.mobileOnly]: {
                        order: 1,
                        marginTop: '48px'
                    },
                }}>
                    <ImageContainer>
                        <Image src={HowItWorksIcon} />
                        <PlayButtonContainer src={PlayButton} onClick={handleClick} />
                    </ImageContainer>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box sx={{ ...modalStyle }}>
                            <CloseVideo onClick={handleClose}>
                                <CloseIcon />
                            </CloseVideo>
                            <Video>
                                <source src={howItWorksVideo} type="video/mp4" />
                                Your browser does not support HTML video.
                            </Video>
                        </Box>
                    </Modal>
                </Box>
                <Box sx={{
                    flex: 1,
                    [breakpoints.mobileOnly]: {
                        order: 0
                    },
                    [breakpoints.tablet]: {
                        paddingLeft: '60px',
                    }
                }}>
                    <Title>
                        <TranslatedText string='how-it-works.title' />
                    </Title>
                    <Line src={TitleLine} />
                    <Steps>
                        <TranslatedText string='how-it-works.subtitle' />
                        {[1, 2, 3, 4, 5].map((step, i) => {
                            return (
                                <Step key={i}>
                                    <TranslatedText string={`how-it-works.step-${step}`} />
                                </Step>
                            )
                        })}
                    </Steps>
                    <Buttons>
                        <Button href="https://play.google.com/store/apps/details?id=bg.myspot" target="_blank">
                            <Image src={GooglePlayColored} alt="google play" />
                        </Button>
                        <Button href="https://apps.apple.com/bg/app/myspot-book-your-dream-spot/id6453559634" target="_blank">
                            <Image src={AppStoreColored} alt="app store" />
                        </Button>
                    </Buttons>
                </Box>
            </Container>
            <Brush src={BrushLeft} />
        </Wrapper>
    )
}

export default HowItWorks;