import styled from 'styled-components';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TitleLine from '../../images/title-line.svg';
import GetYourSpotImage from '../../images/business/get-your-spot.jpg';
import { breakpoints } from '../../theme';
import BrushLeft from '../../images/brush-left.svg';
import TranslatedText from '../TranslatedText';

const Title = styled.h1`
    font-size: 28px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
    max-width: 626px;
    text-align: center;
    ${[breakpoints.tablet]} {
        font-size: 32px;
    }
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    ${(props) => props.sx}
`;

const Buttons = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 32px;
`;

const ButtonSignUp = styled.a.attrs((props) => ({
    href: props.href || '#'
}))`
    background: #C5FFFF;
    border-radius: 30px;
    padding: 16px 24px;
    text-decoration: none;
    color: #044D5E;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
`;

const Wrapper = styled.div`
    position: relative;
    padding: 0;
    background: #fff;
    ${[breakpoints.tablet]} {
        padding: 48px 0 0 0;
    }
`;

const Brush = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    position: absolute;
    top: 300px;
    left: 0;
`;

const Description = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center
    color: #06191E;
    margin-top: 24px;
    max-width: 533px;
    text-align: center;
    margin-bottom: 0;
    letter-spacing: 0.1px;
`;

const GetYourSpot = () => {
    return (
        <Wrapper id='download-section'>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                    [breakpoints.tablet]: {
                        flexDirection: 'row',
                    }
                }}
            >
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Title><TranslatedText string='get-your-spot-business.title' /></Title>
                    <Line src={TitleLine} />
                    <Description><TranslatedText string='get-your-spot-business.subtitle' /></Description>
                    <Buttons>
                        <ButtonSignUp href="#contact-section">
                            <TranslatedText string='signup' />
                        </ButtonSignUp>
                    </Buttons>
                    <Image src={GetYourSpotImage} sx={{
                        marginTop: '24px',
                        width: '100%'
                    }} />
                </Box>
            </Container>
            <Brush src={BrushLeft} />
        </Wrapper>
    )
}

export default GetYourSpot;