import { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import { breakpoints } from '../../theme';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TranslatedText from "../TranslatedText";
import TitleLine from '../../images/title-line.svg';
import ReserveSpot from '../../images/business/reserve.svg';
import StatisticsIcon from '../../images/business/statistics.svg';
import EasyManagement from '../../images/business/easy-management.svg';
import ManageUserAccounts from '../../images/business/manage-user-accounts.svg';
import UserFriendlyInterface from '../../images/business/user-friendly-interface.svg';

const backgrounds = {
    reserve: '#044D5E',
    statistics: '#ABFFC1',
    easyManagement: '#FFFF69',
    manageUserAccounts: '#C5FFFF',
    UFinterface: '#FF781E'
}

const FeaturesContainer = styled.div`
    background: #fff;
    padding-top: 76px;
    padding-bottom: 56px;
    ${[breakpoints.tablet]} {
        padding: 76px 16px 48px 16px;
    }
`;

const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const FeatureWrapper = styled.div`
    box-shadow: 0px 10px 20px rgba(47, 21, 135, 0.06);
    border-radius: 20px;
    padding: 24px 28px 48px 28px;
`;

const IconContainer = styled.div`
    background: ${(props) => backgrounds[props.type]};
    width: 180px;
    height: 180px;
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`

`;

const Description = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #9496A5;
    margin-top: 24px;
`;

const data = [
    {
        icon: ReserveSpot,
        description: 'features-business.manage',
        type: 'reserve'
    },
    {
        icon: StatisticsIcon,
        description: 'features-business.statistics',
        type: 'statistics'
    },
    {
        icon: EasyManagement,
        description: 'features-business.management',
        type: 'easyManagement'
    },
    {
        icon: ManageUserAccounts,
        description: 'features-business.manage-accounts',
        type: 'manageUserAccounts'
    },
    {
        icon: UserFriendlyInterface,
        description: 'features-business.control',
        type: 'UFinterface'
    }
];

const Features = () => {
    return (
        <FeaturesContainer id='features-section'>
            <Container maxWidth="lg">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Title>
                        <TranslatedText string='features-business.title' />
                    </Title>
                    <Line src={TitleLine} />
                </Box>
            </Container>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '48px',
                    overflowX: 'scroll',
                    scrollSnapAlign: 'center',
                    scrollbarWidth: '220px',
                    paddingBottom: '48px',
                    gap: '16px',
                    position: 'relative',
                    '::-webkit-scrollbar': {
                        width: '260px',
                        height: '6px'
                    },
                    '::-webkit-scrollbar-track': {
                        background: '#EDEFF2'
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5A6977',
                        borderRadius: '4px'
                    },
                }}>
                {data.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            flex: '0 0 327px'
                        }}>
                        <FeatureWrapper>
                            <IconContainer type={item.type}>
                                <Icon src={item.icon} alt={item.description} />
                            </IconContainer>
                            <Description>
                                <TranslatedText string={item.description} />
                            </Description>
                        </FeatureWrapper>
                    </Box>
                ))}
            </Container>
        </FeaturesContainer>
    )
}

export default Features;