import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TranslatedText from '../TranslatedText';
import LogoImage from '../../images/logo-white.svg';
import GooglePlayColored from '../../images/google-play-colored.svg';
import AppStoreColored from '../../images/app-store-colored.svg';
import { breakpoints } from '../../theme';

const Wrapper = styled.div`
    background: ${(props) => props.isSpecialOffer ? 'transparent' : '#fff'};
`;

const FooterContainer = styled.div`
    background: #06191E;
    border-radius: 80px 80px 0 0;
    position: relative;
    
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;

`;

const Logo = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`

`;

const FooterHeadLink = styled.a.attrs((props) => ({
    href: props.src || ''
}))`
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    text-decoration: none;
`;

const FooterLegalLinkSPA = styled(Link).attrs((props) => ({
    to: props.to || ''
}))`
    color: #C5FFFF;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    text-decoration: none;
`;

const FooterLegalLink = styled.a.attrs((props) => ({
    href: props.to || ''
}))`
    color: #C5FFFF;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    text-decoration: none;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    ${[breakpoints.mobileOnly]} {
        flex-wrap: wrap;
    }
`;

const Button = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: props.target,
    disabled: props.disabled
}))`
    position: relative;
    &[disabled] {
        pointer-events: none;
        cursor: default;
    }
`;

const ButtonSignUp = styled.a.attrs((props) => ({
    href: props.href || '#'
}))`
    padding: 11px 24px;
    text-decoration: none;
    color: #06191E;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    background: #ABFFC1;
    border: 2px solid #06191E;
    border-radius: 30px;
`;

const ButtonContactSale = styled.a.attrs((props) => ({
    href: props.href || '#'
}))`
    padding: 9px 24px;
    text-decoration: none;
    background: #06191E;
    border: 2px solid #ABFFC1;
    border-radius: 30px;
    color: #ABFFC1;
`;

const VerticalLine = styled.div`
    width: 1px;
    height: 100%;
    background: #9496A5;
    min-height: 42px;
    ${[breakpoints.mobileOnly]} {
        display: none;
    }
`;

const LogoLink = styled(Link).attrs((props) => ({
    to: props.to || '#'
}))`
    display: block;
`;

const PATH_SPECIAL_OFFER = '/special-offer';
const Footer = () => {
    const location = useLocation();
    const [pathUrl, setPathUrl] = useState('');

    useEffect(() => {
        setPathUrl(location.pathname);
    }, [location]);

    return (
        <Wrapper isSpecialOffer={pathUrl === PATH_SPECIAL_OFFER}>
            <FooterContainer>
                <Container maxWidth="lg">
                    <Box sx={{
                        paddingTop: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <LogoLink to='/business'>
                            <Logo src={LogoImage} />
                        </LogoLink>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        padding: '32px 0',
                        [breakpoints.tablet]: {
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '64px',
                        }
                    }}>
                        <FooterHeadLink src=''><TranslatedText string='footer.features' /></FooterHeadLink>
                        <FooterHeadLink src=''><TranslatedText string='footer.how-it-works' /></FooterHeadLink>
                        <FooterHeadLink src=''><TranslatedText string='footer.download-app' /></FooterHeadLink>
                        <FooterHeadLink src=''><TranslatedText string='footer.faq' /></FooterHeadLink>
                    </Box>
                </Container>
                <Box sx={{
                    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    padding: '32px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    [breakpoints.tablet]: {
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '64px',
                    }
                }}>
                    <FooterLegalLinkSPA to='/business/terms'><TranslatedText string='footer.terms' /></FooterLegalLinkSPA>
                    <FooterLegalLinkSPA to='/business/privacy-policy'><TranslatedText string='footer.privacy-policy' /></FooterLegalLinkSPA>
                    <FooterLegalLinkSPA to='/business/cookie-policy'><TranslatedText string='footer.cookie-policy' /></FooterLegalLinkSPA>
                    <FooterLegalLink to='#contact-section'><TranslatedText string='footer.contact-us' /></FooterLegalLink>
                    <FooterLegalLink to='#contact-section'><TranslatedText string='footer.feature-requests' /></FooterLegalLink>
                </Box>
                <Container maxWidth="lg" sx={{
                    paddingTop: '35px',
                    paddingBottom: '32px'
                }}>
                    <Box>
                        <Buttons>
                            <ButtonSignUp href="#contact-section">
                                <TranslatedText string='signup' />
                            </ButtonSignUp>
                            <ButtonContactSale href='#contact-section'>
                                <TranslatedText string='contact-sales' />
                            </ButtonContactSale>
                            <VerticalLine />
                            <Button href="https://play.google.com/store/apps/details?id=bg.myspot" target="_blank">
                                <Image src={GooglePlayColored} alt="google play" />
                            </Button>
                            <Button href="https://apps.apple.com/bg/app/myspot-book-your-dream-spot/id6453559634" target="_blank">
                                <Image src={AppStoreColored} alt="app store" />
                            </Button>
                        </Buttons>
                    </Box>
                </Container>
            </FooterContainer>
        </Wrapper>
    )
}

export default Footer;