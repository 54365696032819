import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { breakpoints } from '../theme';
import TranslatedText from '../components/TranslatedText';

const Wrapper = styled.div`
    background: #fff;
`;

const Title = styled.h1`
    text-align: center;
`;

const LastUpdated = styled.span`
    text-align: center;
`;

const List = styled.ol`
    counter-reset: item;
    ${breakpoints.mobileOnly} {
        padding: 0;
    }
`;

const Item = styled.li`
    display: block;
    padding: 8px 0;
    :before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight: bold;
    }
`;

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: '48px',
                    paddingBottom: '48px',
                    [breakpoints.tablet]: {
                        paddingTop: '96px',
                        paddingBottom: '337px',
                        position: 'relative'
                    }
                }}
            >
                <Title>
                    <TranslatedText string='privacy-policy.title' />
                </Title>
                <LastUpdated><TranslatedText string='privacy-policy.updated' /> 25.04.2023</LastUpdated>
                <List>
                    <Item>
                        <TranslatedText string='privacy-policy.introduction.title' html={true} />
                        <TranslatedText string='privacy-policy.introduction.description' />
                        <List>
                            {t('privacy-policy.introduction.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.data-controller.title' />
                        <List>
                            {t('privacy-policy.data-controller.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.supervisory-authority.title' />
                        <List>
                            {t('privacy-policy.supervisory-authority.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                            <ul>
                                {t('privacy-policy.supervisory-authority.inner-points', { returnObjects: true }).map((point, i) => (
                                    <li dangerouslySetInnerHTML={{ __html: point }} key={i} />
                                ))}
                            </ul>
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.purpose-and-scope.title' />
                        <List>
                            {t('privacy-policy.purpose-and-scope.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                            <ul>
                                {t('privacy-policy.purpose-and-scope.inner-points', { returnObjects: true }).map((point, i) => (
                                    <li dangerouslySetInnerHTML={{ __html: point }} key={i} />
                                ))}
                            </ul>
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.definitions.title' />
                        <List>
                            {t('privacy-policy.definitions.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                    <List>
                                        {t('privacy-policy.definitions.inner-points', { returnObjects: true }).map((point, i) => (
                                            <Item key={i}>
                                                <span dangerouslySetInnerHTML={{ __html: point }} />
                                            </Item>
                                        ))}
                                    </List>
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.principles.title' />
                        <List>
                            {t('privacy-policy.principles.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                            <ul>
                                {t('privacy-policy.principles.inner-points', { returnObjects: true }).map((point, i) => (
                                    <li dangerouslySetInnerHTML={{ __html: point }} key={i} />
                                ))}
                            </ul>
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.data-collected-and-processed.title' />
                        <List>
                            {t('privacy-policy.data-collected-and-processed.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point.title}
                                    {point.description && <p>{point.description}</p>}
                                    {point.points && <List>
                                        {point.points.map((p, j) => <Item>
                                            {p.title}
                                            {p.description && <p dangerouslySetInnerHTML={{ __html: p.description }} />}
                                            {p.inner && <ul>{p.inner.map((po, i) => (
                                                <li dangerouslySetInnerHTML={{ __html: po }} key={i} />
                                            ))}
                                            </ul>}
                                        </Item>)}
                                    </List>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.legal-basis.title' />
                        <List>
                            {t('privacy-policy.legal-basis.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                            <ul>
                                {t('privacy-policy.legal-basis.inner-points', { returnObjects: true }).map((point, i) => (
                                    <li key={i}>{point}</li>
                                ))}
                            </ul>
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.purpose-of-processing.title' />
                        <List>
                            {t('privacy-policy.purpose-of-processing.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point.title}
                                    {point.points && <ul>{
                                        point.points.map((p, j) => <li key={j}>{p}</li>)
                                    }</ul>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.period-for-data-storing.title' />
                        <List>
                            {t('privacy-policy.period-for-data-storing.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point.title}
                                    {point.description && <p>{point.description}</p>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.mandatory-and-voluntary-nature.title' />
                        <List>
                            {t('privacy-policy.mandatory-and-voluntary-nature.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                            <ul>
                                {t('privacy-policy.mandatory-and-voluntary-nature.inner-points', { returnObjects: true }).map((point, i) => (
                                    <li key={i}>
                                        {point}
                                    </li>
                                ))}
                            </ul>
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.processing-of-personal-data.title' />
                        <List>
                            {t('privacy-policy.processing-of-personal-data.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.protection-of-personal-data.title' />
                        <List>
                            {t('privacy-policy.protection-of-personal-data.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point.title }} />
                                    {point.inner && <ul>
                                        {
                                            point.inner.map((p, j) => <li key={j}>{p}</li>)
                                        }
                                    </ul>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.recipients-of-personal-data.title' />
                        <List>
                            {t('privacy-policy.recipients-of-personal-data.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point.title }} />
                                    {point.inner && <ul>
                                        {
                                            point.inner.map((p, j) => <li key={j}>{p}</li>)
                                        }
                                    </ul>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.rights-of-individuals.title' />
                        <List>
                            {t('privacy-policy.rights-of-individuals.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point.title}
                                    <p>{point.description}</p>
                                    {point.points && <List>{
                                        point.points.map((p, j) => <Item key={j}>
                                            {p.title}
                                            <p>{p.description}</p>
                                        </Item>)
                                    }</List>}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.procedure-to-exercise.title' />
                        <List>
                            {t('privacy-policy.procedure-to-exercise.points', { returnObjects: true }).map((point, i) =>
                                <Item key={i}>
                                    {typeof point === 'object' && <>{point.title}<ul>{point.inner.map((p, j) => <li key={j} >{p}</li>)}</ul></>}
                                    {typeof point === 'string' && <>{point}</>}
                                </Item>
                            )
                            }
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.right-to-object-to-processing-data.title' />
                        <List>
                            {t('privacy-policy.right-to-object-to-processing-data.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.content-from-other-companies.title' />
                        <List>
                            {t('privacy-policy.content-from-other-companies.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.third-party.title' />
                        <List>
                            {t('privacy-policy.third-party.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    {point}
                                </Item>
                            ))}
                            <br />
                            <b><TranslatedText string='privacy-policy.third-party.registration-and-authentication.title' /></b>
                            <ul>
                                {t('privacy-policy.third-party.registration-and-authentication.points', { returnObjects: true }).map((point, i) => (
                                    <li key={i}>
                                        {point}
                                    </li>
                                ))}
                            </ul>
                            <b><TranslatedText string='privacy-policy.third-party.analytics.title' /></b><br />
                            <b><TranslatedText string='privacy-policy.third-party.card-processing-fraud-detection.title' /></b>
                            <ul>
                                {t('privacy-policy.third-party.card-processing-fraud-detection.points', { returnObjects: true }).map((point, i) => (
                                    <li key={i}>
                                        {point}
                                    </li>
                                ))}
                            </ul>
                            <b><TranslatedText string='privacy-policy.third-party.identify-verification.title' /></b>
                            <ul>
                                {t('privacy-policy.third-party.identify-verification.points', { returnObjects: true }).map((point, i) => (
                                    <li key={i}>
                                        {point}
                                    </li>
                                ))}
                            </ul>
                            <b><TranslatedText string='privacy-policy.third-party.hosting.title' /></b>
                            <ul>
                                {t('privacy-policy.third-party.hosting.points', { returnObjects: true }).map((point, i) => (
                                    <li key={i}>
                                        {point}
                                    </li>
                                ))}
                            </ul>
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.changes.title' />
                        <List>
                            {t('privacy-policy.changes.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                        </List>
                    </Item>
                    <Item><TranslatedText string='privacy-policy.contacts.title' />
                        <List>
                            {t('privacy-policy.contacts.points', { returnObjects: true }).map((point, i) => (
                                <Item key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: point }} />
                                </Item>
                            ))}
                        </List>
                    </Item>
                </List >
            </Container >
        </Wrapper>
    );
}

export default PrivacyPolicy;