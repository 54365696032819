import { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TranslatedText from "../TranslatedText";
import Smiley from '../../images/smiley.svg';
import TitleLine from '../../images/title-line.svg';
import ReserveSpot from '../../images/user/reserve.svg';
import Explore from '../../images/user/explore.svg';
import SaveTime from '../../images/user/save-time.svg';
import BeachExperience from '../../images/user/beach-experience.svg';
import FindSpot from '../../images/user/find-spot.svg';
import { breakpoints } from '../../theme';

const backgrounds = {
    reserve: '#044D5E',
    explore: '#ABFFC1',
    savetime: '#FFFF69',
    beachexperience: '#C5FFFF',
    findspot: '#FF781E'
}

const FeaturesContainer = styled.div`
    background: #fff;
    ${[breakpoints.mobileOnly]} {
        padding: 0 16px 48px 16px;
    }
`;

const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const FeatureWrapper = styled.div`
    box-shadow: 0px 10px 20px rgba(47, 21, 135, 0.06);
    border-radius: 20px;
    padding: 24px 28px 48px 28px;
`;

const IconContainer = styled.div`
    background: ${(props) => backgrounds[props.type]};
    width: 180px;
    height: 180px;
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`

`;

const FeatureTitle = styled.h1`
    color: #044D5E;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    text-align: center;
    margin-top: 16px;
`;

const Description = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #9496A5;
    margin-top: 24px;
`;

const data = [
    {
        icon: ReserveSpot,
        title: 'features.reserve.title',
        description: 'features.reserve.description',
        type: 'reserve'
    },
    {
        icon: Explore,
        title: 'features.explore.title',
        description: 'features.explore.description',
        type: 'explore'
    },
    {
        icon: SaveTime,
        title: 'features.save-time.title',
        description: 'features.save-time.description',
        type: 'savetime'
    },
    {
        icon: BeachExperience,
        title: 'features.hassle-free.title',
        description: 'features.hassle-free.description',
        type: 'beachexperience'
    },
    {
        icon: FindSpot,
        title: 'features.find-spot.title',
        description: 'features.find-spot.description',
        type: 'findspot'
    }
]

const ScrollContainer = styled.div`
    background: red;
    width: 220px;
    height: 6px;
    position: relative;
    margin: 0 auto;
`;

const Scroll = styled.div`
    height: 100%;
    width: ${(props) => props.width}px;
    background: green;
    position: absolute;
    left: ${(props) => props.position}px;
    top:0;
`;

const Features = () => {
    const [scroll, setScroll] = useState(0);
    const [scrollWidth, setScrollWidth] = useState(220);
    const scrollableRef = useRef();

    useEffect(() => {
        setScrollWidth(scrollableRef.current.scrollWidth / 40);
    }, [scrollableRef]);

    const handleScroll = event => {
        const scrollWidth = event.currentTarget.scrollWidth;
        const clientWidth = scrollableRef.current.clientWidth;
        const fakeScrollableArea = 220;
        const thumbWidth = 56;
        const scrollLeft = event.currentTarget.scrollLeft;
        const originalScrollableArea = scrollWidth - clientWidth;

        const fakeScrollPosition = (scrollLeft / originalScrollableArea) * (fakeScrollableArea - thumbWidth);
        // setScroll(scrollLeft);
        // console.log(fakeScrollPosition);
        setScroll(fakeScrollPosition);
    };
    return (
        <FeaturesContainer>
            <Container maxWidth="lg">
                <Box sx={{
                    padding: '48px 0 48px 0',
                    [breakpoints.tablet]: {
                        padding: '78px 0 22px 0'
                    }
                }}>
                    <img src={Smiley} id='features-section' loading="lazy" />
                </Box>
            </Container>
            <Container maxWidth="lg">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Title>
                        <TranslatedText string='features.title' />
                    </Title>
                    <Line src={TitleLine} />
                </Box>
            </Container>
            <Container
                ref={scrollableRef}
                onScroll={handleScroll}
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '48px',
                    overflowX: 'scroll',
                    scrollSnapAlign: 'center',
                    scrollbarWidth: '220px',
                    paddingBottom: '48px',
                    gap: '16px',
                    position: 'relative',
                    '::-webkit-scrollbar': {
                        width: '260px',
                        height: '6px'
                    },
                    '::-webkit-scrollbar-track': {
                        background: '#EDEFF2'
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5A6977',
                        borderRadius: '4px'
                    },
                }}>
                {data.map((item, index) => (
                    <Box
                        key={index}
                        sx={{
                            flex: '0 0 327px',
                        }}>
                        <FeatureWrapper>
                            <IconContainer type={item.type}>
                                <Icon src={item.icon} alt={item.title} />
                            </IconContainer>
                            <FeatureTitle>
                                <TranslatedText string={item.title} />
                            </FeatureTitle>
                            <Description>
                                <TranslatedText string={item.description} />
                            </Description>
                        </FeatureWrapper>
                    </Box>
                ))}
            </Container>
            {/* <ScrollContainer>
                <Scroll position={scroll} width={scrollWidth} />
            </ScrollContainer> */}
        </FeaturesContainer>
    )
}

export default Features;