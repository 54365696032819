import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ThreeDots } from 'react-loader-spinner';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import TranslatedText from '../TranslatedText';
import { breakpoints } from '../../theme';

const Wrapper = styled.div`
    background: #fff;
`;

const Title = styled.h2`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
    text-align: center;
`;

const Subtitle = styled.h1`
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #044D5E;
    margin: 0;
`;

const Description = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #9496A5;
    max-width: 449px;
    margin: 24px auto 0 auto;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px auto 0 auto;
    width: 100%;
    gap: 24px;
    ${[breakpoints.tablet]} {
        width: 372px;
    }
`;

const Input = styled.input.attrs((props) => ({
    type: props.type || 'text',
    placeholder: props.placeholder || ''
}))`
    padding: 18px 23px;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #EFF0F9;
    border-radius: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000;
    box-sizing: border-box;
`;

const Submit = styled.button.attrs((props) => ({
    disabled: props.disabled ? props.disabled : false,
}))`
    outline: none;
    border: none;
    padding: ${(props) => props.sending ? '11px 24px' : '16px 24px'};
    background: ${(props) => props.disabled ? '#ccc' : '#044D5E'};
    border-radius: 30px;

    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${(props) => props.disabled ? '#fff' : '#C5FFFF'};
    display: flex;
    align-items: center;
`;

const MessageContainer = styled.div`
    padding: 0 18px 18px 18px;
    background: #C5FFFF;
    display: flex;
    border-radius: 12px;
    font-weight: bold;
    color: #044D5E;
    ${[breakpoints.tablet]} {
        margin-top: 8px;
        padding: 12px;

    }
`;
const Contact = () => {
    const {t} = useTranslation();
    const [legalName, setLegalName] = useState('');
    const [representationName, setRepresentationName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [resMessage, setResMessage] = useState(null);

    const [submitClicked, setSubmitClicked] = useState(false);

    const handleLegalNameChange = (event) => {
        setLegalName(event.target.value);
    }

    const handleRepresentationNameChange = (event) => {
        setRepresentationName(event.target.value);
    }

    const handlePhoneNumberChange=(event)=>{
        setPhoneNumber(event.target.value);
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }


    useEffect(() => {
        if (
            isValidEmail(email) &&
            isValidatePhoneNumber(phoneNumber) &&
            isNotNullNorEmpty(legalName) &&
            isNotNullNorEmpty(name) &&
            isNotNullNorEmpty(representationName)
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [name, email, legalName, phoneNumber, representationName]);

    const isValidEmail = (email) => {
        return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(email);
    }

    const isValidatePhoneNumber = (phoneNumber) => {
        const pattern = /^(\+\d{1,3}\s?)?(\(\d{3}\)\s\d{3}-\d{4}|\d{10})$/;
        return pattern.test(phoneNumber);
    }

    const isNotNullNorEmpty = (text) => {
        return Boolean(text && text.trim());
    }

    const handleSubmit = async (e) => {
        setSubmitClicked(true);
        const response = await fetch(`${process.env.REACT_APP_BUSINESS_INQURIES_URL_API}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                companyLegalName:legalName,
                companyRepresentationName:representationName,
                businessRepresentativeFullName: name,
                email,
                phoneNumber
            })
        });

        if (response.ok) {
            setResMessage(t('subscribe-success'));
        } else {
            setResMessage(t('subscribe-fail'));
        }
        setSubmitClicked(false);
    }

    return (
        <Wrapper>
            <Container
                id="contact-section"
                sx={{
                    paddingBottom: '64px',
                    [breakpoints.tablet]: {
                        paddingBottom: '104px',
                    }
                }}>
                <Subtitle>
                    <TranslatedText string='contact-us-business.title'/>
                </Subtitle>
                <Title>
                    <TranslatedText string='contact-us-business.subtitle'/>
                </Title>
                <Description>
                    <TranslatedText string='contact-us-business.description'/>
                </Description>
                <Form style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gridRowGap: '10px',
                    gridColumnGap: '20px',
                    [breakpoints.tablet]: {
                        gridTemplateColumns: '1fr',
                        gridColumnGap: '0px'
                    }
                }}>
                    <div>
                        <Input style={{ marginBottom: '10px' }} placeholder={t('contact-us-business.form-company-legal-name-placeholder')}
                               onChange={handleLegalNameChange}/>
                        <Input placeholder={t('contact-us-business.form-name-placeholder')}  onChange={handleNameChange}/>
                    </div>
                    <div>
                        <Input style={{ marginBottom: '10px' }} placeholder={t('contact-us-business.form-representation-name-placeholder')}
                               onChange={handleRepresentationNameChange}/>
                        <Input placeholder={t('contact-us-business.form-phone-number-placeholder')}
                               onChange={handlePhoneNumberChange}/>
                    </div>
                    <Input placeholder={t('contact-us-business.form-email-placeholder')} onChange={handleEmailChange} style={{ gridColumn: 'span 2' }}/>

                    <Submit
                        style={{ gridColumn: 'span 2' }}
                        sending={submitClicked}
                        disabled={!isValid}
                        onClick={handleSubmit}
                    >
                        <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#fff"
                            ariaLabel="business-form-loading"
                            wrapperStyle={{
                                marginRight: '6px'
                            }}
                            visible={submitClicked}
                        />
                        {submitClicked ? t('contact-us-business.button-sending') : t('contact-us-business.button-send')}
                    </Submit>
                    {resMessage && <MessageContainer>{resMessage}</MessageContainer>}
                </Form>
            </Container>
        </Wrapper>
    );
}

export default Contact;
