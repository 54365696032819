import { Routes, Route } from "react-router-dom"
import UserHome from './views/Home';
import BusinessHome from './views/BusinessHome';
import Terms from './views/Terms'
import PrivacyPolicy from './views/PrivacyPolicy';
import CookiePolicy from './views/CookiePolicy';
import ScrollToTop from './components/ScrollToTop';
import UserLayout from './components/user/UserLayout';
import BusinessLayout from './components/business/BusinessLayout';
import ScannerResult from './views/ScannerResult';
import SpecialOffer from "./views/SpecialOffer";
import DeleteAccount from "./views/DeleteAccount";
import PaymentSuccess from "./views/PaymentSuccess";
import PaymentFailure from "./views/PaymentFailure";

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/" element={<UserHome />} />
          <Route path="/special-offer" element={<SpecialOffer />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailure />} />

        </Route>
        <Route element={<BusinessLayout />} >
          <Route path="/business" element={<BusinessHome />} />
          <Route path="/business/terms" element={<Terms />} />
          <Route path="/business/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/business/cookie-policy" element={<CookiePolicy />} />
        </Route>
        <Route path="/scanner-result" element={<ScannerResult />} />
      </Routes>
    </>
  );
}

export default App;
