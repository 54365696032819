import styled from 'styled-components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TranslatedText from '../TranslatedText';
import GooglePlay from '../../images/google-play.svg';
import AppStore from '../../images/app-store.svg';
import Background from '../../images/you-need-a-spot-background.svg';
import Phones from '../../images/you-need-a-spot-phones.svg';
import LogoImage from '../../images/logo-white.svg';
import { breakpoints } from '../../theme';

const Wrapper = styled.div`
    background: #fff;
    padding-top: 0;
    ${[breakpoints.tablet]} {
        padding-top: 230px;
    }
`;

const Title = styled.h1`
    font-weight: 400;
    font-size: 56px;
    line-height: 72px;
    margin: 0;
    color: #ABFFC1;
    font-family: dela;
    max-width: 429px;
`;

const Subtitle = styled.h3`
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.15px;
    margin: 16px 0 0 0;
    color: #ABFFC1;
    max-width: 375px;
`;

const Buttons = styled.div`
    margin-top: 88px;
    display: flex;
    align-items: center;
    gap: 32px;
`;

const Button = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: props.target,
    disabled: props.disabled
}))`
    position: relative;
    &[disabled] {
        pointer-events: none;
        cursor: default;
    }
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    display: block;
`;

const PhonesImage = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    width: 100%;
    max-width: 880px;
`;
const Logo = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`

`;
const YouNeedASpot = () => {
    return (
        <Wrapper>
            <Container maxWidth="lg" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                position: 'relative',
                backgroundImage: ` url(${Background})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '32px',
                paddingTop: '48px !important',
                paddingBottom: '48px !important',
                paddingLeft: '32px !important',
                paddingRight: '32px !important',
                marginTop: '0',
                marginBottom: '0',
                [breakpoints.tablet]: {
                    paddingLeft: '57px !important',
                    paddingRight: '57px !important',
                    paddingTop: '47px !important',
                    paddingBottom: '74px !important',
                }
            }}>
                <Logo src={LogoImage} />
                <Box sx={{
                    order: 1,
                    [breakpoints.tablet]: {
                        flex: '0 0 40%',
                        padding: '91px 0 0 0'
                    }
                }}>
                    <Title>
                        <TranslatedText string='need-a-spot.title' />
                    </Title>
                    <Subtitle>
                        <TranslatedText string='need-a-spot.subtitle' />
                    </Subtitle>
                    <Buttons>
                        <Button href="https://play.google.com/store/apps/details?id=bg.myspot" target="_blank">
                            <Image src={GooglePlay} alt="google play" />
                        </Button>
                        <Button href="https://apps.apple.com/bg/app/myspot-book-your-dream-spot/id6453559634" target="_blank">
                            <Image src={AppStore} alt="app store" />
                        </Button>
                    </Buttons>
                </Box>
                <Box sx={{
                    order: 0,
                    marginTop: '32px',
                    [breakpoints.tablet]: {
                        position: 'absolute',
                        right: '-30px',
                        top: '-108px',
                        marginTop: '0'
                    }
                }}>
                    <PhonesImage src={Phones} />
                    {/* <ImgWithFallback
                        src={HeaderPhonesWebp}
                        fallback={HeaderPhonesPNG}
                        alt='header phones'
                    /> */}
                </Box>
            </Container>
        </Wrapper>
    )
}

export default YouNeedASpot;