import styled from 'styled-components';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TranslatedText from '../TranslatedText';
import TitleLine from '../../images/title-line.svg';
import GooglePlayColored from '../../images/google-play-colored.svg';
import AppStoreColored from '../../images/app-store-colored.svg';
import BrushRight from '../../images/brush-right.svg';
import GetYourSpotImage from '../../images/user/get-your-spot.jpg';
import { breakpoints } from '../../theme';

const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
`;

const Line = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    ${(props) => props.sx}
`;

const Buttons = styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 32px;
`;

const Button = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: props.target,
    disabled: props.disabled
}))`
    position: relative;
    &[disabled] {
        pointer-events: none;
        cursor: default;
    }
`;

const Wrapper = styled.div`
    position: relative;
    padding: 48px 0;
    background: #fff;
`;

const Brush = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    position: absolute;
    top: -200px;
    right: 0;
`;

const Description = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center
    color: #06191E;
    margin-top: 24px;
    max-width: 533px;
    text-align: center;
    margin-bottom: 0;
`;

const GetYourSpot = () => {
    return (
        <Wrapper id='download-section'>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                    [breakpoints.tablet]: {
                        flexDirection: 'row',
                    }
                }}
            >
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Title>
                        <TranslatedText string='get-your-spot.title' />
                    </Title>
                    <Line src={TitleLine} />
                    <Description>
                        <TranslatedText string='get-your-spot.subtitle' />
                    </Description>
                    <Buttons>
                        <Button href="https://play.google.com/store/apps/details?id=bg.myspot" target="_blank">
                            <Image src={GooglePlayColored} alt="google play" />
                        </Button>
                        <Button href="https://apps.apple.com/bg/app/myspot-book-your-dream-spot/id6453559634" target="_blank">
                            <Image src={AppStoreColored} alt="app store" />
                        </Button>
                    </Buttons>
                    <Image src={GetYourSpotImage} sx={{
                        marginTop: '26px',
                        width: '100%'
                    }} />
                </Box>
            </Container>
            <Brush src={BrushRight} />
        </Wrapper>
    )
}

export default GetYourSpot;