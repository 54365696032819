import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ThreeDots } from 'react-loader-spinner';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { breakpoints } from '../theme';
import TranslatedText from '../components/TranslatedText';
import SpecialOfferImg from '../images/special-offer.svg';

const NewsletterContainer = styled.div`
    padding: 120px 0;
`;

const NewsLetter = styled.div`
    display: flex;
    alignItems: center;
    flex-direction: column;
    max-width: 1125px;
    width: 100%;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    border-radius: 20px;
    background: #fff;
    padding: 48px 0;
    margin: 0 auto;
    position: relative;
    gap: 16px;
    ${[breakpoints.tablet]} {
        flex-direction: row;
        padding: 57px 36px 30px 36px;
        gap: 0;
    }
`;

const Image = styled.img.attrs((props) => ({
    src: props.src || '',
    alt: props.alt || '',
    loading: 'lazy'
}))`
    ${[breakpoints.mobileOnly]} {
        width: 100%;
    }
    ${[breakpoints.tablet]} {
        position: absolute;
        top: -40px;
        left: -113px;
    }
`;

const Title = styled.h1`
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    color: #044D5E;
    font-family: dela;
    max-width: 500px;
`;

const Description = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #06191E;
    margin-top: 24px;
    margin-bottom: 0;
    max-width: 425px;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 40px;
    ${[breakpoints.mobileOnly]} {
        margin-top: 32px;
    }
`;

const Input = styled.input.attrs((props) => ({
    type: 'text',
    placeholder: props.placeholder || ''
}))`
    border: 2px solid #EFF0F9;
    border-radius: 30px;
    padding: 18px 0 18px 23px;
    max-width: 435px;
    width: 100%;
`;

const SubscribeButton = styled.button.attrs((props) => ({
    disabled: props.disabled ? props.disabled : false
}))`
    background: ${(props) => props.disabled ? '#ccc' : '#044D5E'};
    border-radius: 30px;
    padding: ${(props) => props.sending ? '11px 24px' : '16px 24px'};
    оutline: none;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${(props) => props.disabled ? '#fff' : '#C5FFFF'};
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const MessageContainer = styled.div`
    padding: 0 18px 18px 18px;
    background: #C5FFFF;
    display: flex;
    border-radius: 12px;
    font-weight: bold;
    color: #044D5E;
    ${[breakpoints.tablet]} {
        margin-top: 8px;
        padding: 12px;

    }
`;

const SpecialOffer = () => {
    const [email, setEmail] = useState(null);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [resMessage, setResMessage] = useState(null);
    const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (isValidEmail(email)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }, [email]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handleSubmit = (e) => {
        setSubmitClicked(true);
        emailjs.send(`${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, `${process.env.REACT_APP_EMAILJS_USER_TEMPLATE_ID}`, {
            user_email: email,
        }, `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}`)
            .then((result) => {
                setResMessage(t('subscribe-success'))
                setSubmitClicked(false);
            }, (error) => {
                setResMessage(t('subscribe-fail'))
                setSubmitClicked(false);
            });
    }

    const isValidEmail = (email) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(email);
    }

    const handlePrivacyPolicyChecked = () => {
        setPrivacyPolicyChecked(!privacyPolicyChecked);
    }

    return (
        <NewsletterContainer>
            <NewsLetter>
                <Box sx={{
                    flex: '0 0 30%',
                    [breakpoints.mobileOnly]: {
                        order: 0,
                        padding: '0 16px'
                    }
                }}>
                    <Image src={SpecialOfferImg} />
                </Box>
                <Box sx={{
                    [breakpoints.mobileOnly]: {
                        order: 1,
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    },
                    flex: '1',
                    paddingLeft: '16px',
                }}>
                    <Title>
                        <TranslatedText string='newsletter.title' />
                    </Title>
                    <Description>
                        <TranslatedText string='newsletter.description' />
                    </Description>
                    <>
                        <FormGroup>
                            <FormControlLabel
                                required
                                control={
                                    <Checkbox
                                        checked={privacyPolicyChecked}
                                        onClick={handlePrivacyPolicyChecked} />
                                }
                                label={<span dangerouslySetInnerHTML={{ __html: t('newsletter.privacy-policy') }} />}
                            />
                        </FormGroup>
                    </>
                    <InputContainer>
                        <Input placeholder={t('newsletter.input')} onChange={handleEmailChange} />
                        <SubscribeButton
                            sending={submitClicked}
                            disabled={(!isEmailValid || !privacyPolicyChecked)}
                            onClick={handleSubmit}>
                            <ThreeDots
                                height="30"
                                width="30"
                                radius="9"
                                color="#fff"
                                ariaLabel="business-form-loading"
                                wrapperStyle={{
                                    marginRight: '6px'
                                }}
                                visible={submitClicked}
                            />
                            {submitClicked ? t('newsletter.button-subscribing') : t('newsletter.button-subscribe')}
                        </SubscribeButton>
                    </InputContainer>
                    {resMessage && <MessageContainer>{resMessage}</MessageContainer>}
                </Box>
            </NewsLetter>
        </NewsletterContainer>
    )
}

export default SpecialOffer;